@import '../color-styles';

.specials {
    margin-top: 5.24em;
    --text-col: #111;
    // background: black;
    color: var(--text-col);
    position: relative;
    padding: 2.4em 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: #111;
        font-weight: 400;
    }

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // background: #efefef;
        z-index: -1;
        // clip-path: polygon(0 0, 100% 3%, 100% 100%, 0 96%);
    }

    #title {
        text-align: center;
        // color: #0A2239;
        font-size: 3.34rem;
        color: #111;
        font-weight: 500;

    }

    .container {
        // background: coral;
        max-width: 1340px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: stretch;
        margin: 5em auto;
        padding: 0;

        .left {
            flex: 1;
            // background: seagreen;
            height: inherit;

            .img-holder {

                // background: #000;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                height: 100%;
                position: relative;



                img {
                    width: 80%;
                    aspect-ratio: 1920/948;
                    box-shadow: 0 0 0 3px #111, 1.24em 1.24em 0 01px $primary-color-1-dark;
                    // box-shadow: 0 0 0 3px #111, 1.24em 1.24em 0 01px #F0F0E4;
                    object-fit: cover;
                    border-radius: 1em;
                    position: relative;
                }
            }
        }

        .right {
            flex: 1;
            // background: wheat;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: 0;

            .text-container {
                // background: coral;

                margin: 5em 0;
                padding: 0 5em;

                h3 {
                    font-size: 1.54rem;
                    margin-bottom: 0.7em;
                    font-weight: 550;
                    // text-transform: uppercase;
                }

                p {
                    font-size: 1.24rem;
                    font-weight: 500;
                    line-height: 1.6;
                    margin-bottom: 2em;
                    color: #666;
                }

                button {
                    background: #111;
                    color: #fefefe;
                    border: none;
                    border-radius: 5em;
                    font-size: 0.94rem;
                    padding: 1.14em 2.14em;
                    font-weight: bolder;
                    text-transform: uppercase;
                    cursor: pointer;

                    &:hover {
                        background: #0A2239;
                        color: $primary-color-1-dark;
                        transition: 0.24s;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 920px) {
    .specials {
        padding: 0.84em;

        .container {
            flex-direction: column;

            // &:nth-child(3) {
            //     flex-direction: column-reverse;
            // }
        }
    }
}