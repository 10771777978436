footer {
  position: relative;
  background: #efefef;
}
footer .footer-holder {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}
footer .footer-holder .footer-actual {
  min-width: 1240px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: inherit;
  justify-content: flex-start;
  margin: auto;
  margin: 3em 0;
}
footer .footer-holder .footer-actual .footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 0;
  color: #333;
  text-decoration: none;
  font-size: 1.73rem;
  font-weight: 500;
  flex: 1;
  position: relative;
}
footer .footer-holder .footer-actual .footer-logo::after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 3px;
  top: 0;
  left: 100%;
  background: #333;
}
footer .footer-holder .footer-actual .footer-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex: 3;
  padding: 0 3em;
}
footer .footer-holder .footer-actual .footer-links .gen-links {
  background: transparent;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
footer .footer-holder .footer-actual .footer-links .gen-links .gen-links-single {
  text-decoration: none;
  background: transparent;
  text-decoration: none;
  width: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: 600;
  position: relative;
  color: #333;
  margin-left: 2em;
}
footer .footer-holder .footer-actual .footer-links .gen-links .gen-links-single:hover {
  color: #1ECBE1;
}
footer .footer-holder .footer-actual .footer-links .socials {
  background: transparent;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}
footer .footer-holder .footer-actual .footer-links .socials li {
  padding: 1em;
}
footer .footer-holder .footer-actual .footer-links .socials li i {
  color: #333;
}
footer .footer-holder .footer-actual .footer-links .socials li:hover {
  transform: translate(0, -5px);
  transition: 0.34s;
}
footer p {
  font-size: 14px;
  color: #111;
  max-width: 1240px;
  margin: auto;
  text-align: center;
  padding: 1em;
}

@media screen and (max-width: 920px) {
  .footer-holder {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
  }
  .footer-holder .footer-actual {
    min-width: 1240px;
    display: flex;
    flex-direction: row;
    height: inherit;
    justify-content: flex-start;
    margin: auto;
    margin: 3em 0;
  }
  .footer-holder .footer-actual .footer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 0;
    color: #fefefe;
    text-decoration: none;
    font-size: 1.73rem;
    font-weight: 500;
    flex: 1;
    position: relative;
  }
  .footer-holder .footer-actual .footer-logo::after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 3px;
    top: 0;
    left: 100%;
    background: #fff;
  }
  .footer-holder .footer-actual .footer-links {
    display: none;
  }
}/*# sourceMappingURL=Footer.css.map */