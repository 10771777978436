@import '../text-styles';
@import '../color-styles';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highloght-color: transparent;
}

nav {
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    // background: #134244;
    // background: #1470AF;
    // background: #1470af;
    // background: #0A2239;
    // background: #fff;
    height: 78px;
    padding-top: 1.93em;
    z-index: 1;
    border: 0;
    width: 100vw;
    overflow: hidden;

    div {
        // background: olive;
        max-width: 1240px;
        width: 100%;
        display: flex;
        flex-direction: row;
        height: inherit;
        justify-content: space-between;
        margin: auto;
        max-width: 1240px;

        .nav-logo {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: auto;
            padding: 0;
            background: transparent;
            color: #444;
            text-decoration: none;
            font-size: 1.73rem;
            font-weight: 600;
            // margin-left: 1.4em;
            // background: seagreen;
        }

        .nav-bar {
            background: transparent;
            list-style-type: none;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: stretch;
            // background: coral;
            padding-right: 5em;

            .nav-links {
                text-decoration: none;
                background: transparent;
                text-decoration: none;
                width: 88px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.14em;
                font-weight: 500;
                position: relative;
                color: #111;
                margin-left: 2em;
                // font-family: 'comfortaa';
                
                &:hover {
                    color: #2b6eda;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 10%;
                    left: 50%;
                    transform: translate(-50%, 0);
                    background: #fefefe;
                    height: 1px;
                    width: 0;
                    transition: 0.14s;
                    z-index: 1;
                }
                &:hover::before{
                    width: 100px;
                }

            }

        }

        .menu-btn {
            // background: lightcoral;
            height: inherit;
            width: auto;
            aspect-ratio: 1/1;
            overflow: hidden;
            display: none;
            justify-content: center;
            align-items: center;
            transition-duration: 0.34s;
            position: relative;
            margin: 0;


            &:focus,
            &:active {
                -webkit-tap-highloght-color: transparent;
            }

            img {
                height: calc(55px * 0.5);
            }

            .upper-menu-bar,
            .lower-menu-bar,
            .lower-menu-bar-close,
            .upper-menu-bar-close {
                background: #000;
                // height: 1.4px;
                height: 2px;
                width: 24px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: 400ms;
            }

            .upper-menu-bar {

                transform: translate(-50%, -5px);
            }

            .lower-menu-bar {

                transform: translate(-50%, 5px);
            }

            .upper-menu-bar-close {

                transform: translate(-50%, 0) rotateZ(-45deg);
            }

            .lower-menu-bar-close {

                transform: translate(-50%, 0) rotate(45deg);
            }

        }

        .mobile-menu {
            display: flex;
            height: 0;
            width: 100%;
            position: absolute;
            top: 100%;
            left: 0;
            background: var(--bg-nav);
            z-index: 300;
            transition: height 0.34s ease-in-out;
            overflow: hidden;
            flex-direction: row;
            justify-content: flex-start;

            .nav-bar {
                background: transparent;
                list-style-type: none;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: inherit;

                .nav-links {
                    text-decoration: none;
                    background: transparent;
                    color: var(--nav-text);
                    text-decoration: none;
                    display: flex;
                    justify-content: flex-start;
                    align-items: stretch;
                    font-size: 2rem;
                    width: inherit;
                    padding: 1em 0;

                }

            }
        }
    }


    @media screen and (max-width: 920px) {

        nav {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            align-items: center;
            background: blue;
            z-index: 11;
        }

        div {
            .nav-logo {
                margin-left: 1.04em;
            }

            .menu-btn {
                display: flex;
            }

            .nav-bar {
                display: none;

                .nav-links {
                    display: none;
                }
            }
        }

    }

    .mobile-menu {
        display: none;
        height: 0;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        background: var(--bg-nav);
        z-index: 3;
        transition: height 0.34s ease-in-out;
        overflow: hidden;
        flex-direction: row;
        justify-content: flex-start;

        .nav-bar {
            // background: transparent;
            list-style-type: none;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: inherit;

            .nav-links {
                text-decoration: none;
                // background: transparent;
                color: var(--nav-text);
                text-decoration: none;
                display: flex;
                justify-content: flex-start;
                align-items: stretch;
                font-size: 2rem;
                width: inherit;
                padding: 1em 0;

            }

        }
    }
}