.specials {
  margin-top: 5.24em;
  --text-col: #111;
  color: var(--text-col);
  position: relative;
  padding: 2.4em 0;
}
.specials h1,
.specials h2,
.specials h3,
.specials h4,
.specials h5,
.specials h6,
.specials p {
  color: #111;
  font-weight: 400;
}
.specials::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.specials #title {
  text-align: center;
  font-size: 3.34rem;
  color: #111;
  font-weight: 500;
}
.specials .container {
  max-width: 1340px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  margin: 5em auto;
  padding: 0;
}
.specials .container .left {
  flex: 1;
  height: inherit;
}
.specials .container .left .img-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  position: relative;
}
.specials .container .left .img-holder img {
  width: 80%;
  aspect-ratio: 1920/948;
  box-shadow: 0 0 0 3px #111, 1.24em 1.24em 0 1px #1ECBE1;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 1em;
  position: relative;
}
.specials .container .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0;
}
.specials .container .right .text-container {
  margin: 5em 0;
  padding: 0 5em;
}
.specials .container .right .text-container h3 {
  font-size: 1.54rem;
  margin-bottom: 0.7em;
  font-weight: 550;
}
.specials .container .right .text-container p {
  font-size: 1.24rem;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 2em;
  color: #666;
}
.specials .container .right .text-container button {
  background: #111;
  color: #fefefe;
  border: none;
  border-radius: 5em;
  font-size: 0.94rem;
  padding: 1.14em 2.14em;
  font-weight: bolder;
  text-transform: uppercase;
  cursor: pointer;
}
.specials .container .right .text-container button:hover {
  background: #0A2239;
  color: #1ECBE1;
  transition: 0.24s;
}

@media screen and (max-width: 920px) {
  .specials {
    padding: 0.84em;
  }
  .specials .container {
    flex-direction: column;
  }
}/*# sourceMappingURL=Projects.css.map */