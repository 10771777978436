// Light Mode Colors
// Primary Colors
$primary-color-1: #195ce3; // main primary color
$primary-color-2: #0c2d6b;
$primary-color-3: #0c1f44;
$primary-color-4: #f6d55c; // complementary color
$primary-color-5: #f2c91f;

// $primary-light: #195ce3; // lighter version of primary color
// $primary-dark: #0c2d6b; // darker version of primary color

// Secondary Colors
$secondary-color-1: #ebeff2;
$secondary-color-2: #b6c0cb;
$secondary-color-3: #7e8a97;
$secondary-color-4: #4e5965;
$secondary-color-5: #2e353f;

// $secondary-light: #ebeff2; // lighter version of secondary color
// $secondary-dark: #4e5965; // darker version of secondary color

// Accent Colors
$accent-color-1: #ff5f5f;
$accent-color-2: #ff3f3f;
$accent-color-3: #ff1f1f;
$accent-color-4: #c60000;
$accent-color-5: #7e0000;

// $accent-light: #ff5f5f; // lighter version of accent color
// $accent-dark: #c60000; // darker version of accent color

// Neutral Colors
$neutral-color-1: #ffffff;
$neutral-color-2: #f2f2f2;
$neutral-color-3: #e5e5e5;
$neutral-color-4: #cfcfcf;
$neutral-color-5: #a6a6a6;
$neutral-color-6: #7a7a7a;
$neutral-color-7: #4d4d4d;
$neutral-color-8: #1f1f1f;
$neutral-color-black: #111;

// $text-primary-light: #1d1d1f; // primary text color on light background
// $text-primary-dark: #ffffff; // primary text color on dark background

// Dark Mode Colors
// Primary Colors
$primary-color-1-dark: #1ECBE1; // main primary color
$primary-color-2-dark: #0a244b;
$primary-color-3-dark: #07172d;
$primary-color-4-dark: #f2b707; // complementary color
$primary-color-5-dark: #ebad00;

// $primary-light-dark: #1b4d9b; // lighter version of primary color
// $primary-dark-dark: #0a244b; // darker version of primary color

// Secondary Colors
$secondary-color-1-dark: #282d34;
$secondary-color-2-dark: #3e454f;
$secondary-color-3-dark: #5f697a;
$secondary-color-4-dark: #8795a1;
$secondary-color-5-dark: #b8c2cc;

// $secondary-light-dark: #282d34; // lighter version of secondary color
// $secondary-dark-dark: #b8c2cc; // darker version of secondary color

// Accent Colors
$accent-color-1-dark: #ff8f8f;
$accent-color-2-dark: #ff6f6f;
$accent-color-3-dark: #ff4f4f;
$accent-color-4-dark: #dc0000;
$accent-color-5-dark: #7e0000;

// $accent-light-dark: #ff8f8f; // lighter version of accent color
// $accent-dark-dark: #dc0000; // darker version of accent color

// Neutral Colors
$neutral-color-1-dark: #1f1f1f;
$neutral-color-2-dark: #282828;
$neutral-color-3-dark: #353535;
$neutral-color-4-dark: #4d4d4d;
$neutral-color-5-dark: #7a7a7a;
$neutral-color-6-dark: #a6a6a6;
$neutral-color-7-dark: #cfcfcf;
$neutral-color-8-dark: #e5e5e5;
$neutral-color-white: #ffffff;

// $text-primary-light-dark: #ffffff; // primary text color on dark background
// $text-primary-dark-dark: #1d1d1f; // primary text color on light background


$brand-twitter:#1da1f2;
$brand-instagram:#e1306c;
$brand-linkedin: #0a66c2;