@import '../color-styles';
@import '../text-styles';

.hero-area {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    overflow: visible;
    position: relative;
    // background: #123;

    // &::before {
    //     content: '';
    //     height: auto;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     top: 0;
    //     position: absolute;
    //     // clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 0% 100%);
    //     background: #fff;
    // }

    .images {
        position: absolute;
        height: 80px;
        left: -10vw;
        animation: drone_move 9s linear infinite;

        &:nth-child(1) {
            top: 20px;
            animation-delay: 3s;

        }

        &:nth-child(2) {
            top: 54px;
            animation-delay: 1s;
            height: 60px;
        }

        &:nth-child(3) {
            top: 20px;
            z-index: 100;
            animation-delay: 5s;
        }
    }

    .hero-sub-area {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        max-width: 1240px;
        height: inherit;
        margin: auto;
        background: transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        overflow: visible;
        // background: sandybrown;

        .gradient-roller {
            position: absolute;
            opacity: 0.3;
            top: 100%;
            left: 0;
            height: 70%;
            width: 70%;
            transform: translate(-50%, -50%);
            z-index: -1;
            filter: blur(100px);
            display: block;

            &::before {
                content: '';
                display: block;
                backdrop-filter: blur(10px);
                height: inherit;
                width: inherit;
            }

            &:nth-child(2) {
                top: 100%;
                left: 20%;
                background-image: radial-gradient(circle, rgba(63, 94, 251, 1) 0%, rgba(252, 70, 107, 1) 100%);
            }
        }

        .part1 {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            // align-items: center;
            height: inherit;
            position: relative;
            overflow: visible;
            padding: 9em 0;
            // background: seagreen;

            .text-slot {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin: 0 0 2.4em 0;
                // background: coral;

                h1 {
                    font-weight: 500;
                    line-height: 1;
                    margin: 0 0 0.24em 0;
                    color: #555;
                    font-size: 4.24rem;

                    strong {
                        font-weight: 500;
                        // color: #4f89e1 ;
                        color: #3d76ca;
                    }
                }

                h2 {
                    font-weight: 500;
                    font-size: 1.74rem;
                    margin-bottom: 1.24em;
                    color: #029602;
                    position: relative;
                    // transition: 0.54s;
                    height: 54px;

                    img {
                        height: 44px;
                        transform: translate(0, 35%);
                        margin: 0 1em 0 0;
                    }
                }

                h3 {
                    font-size: 1.53rem;
                    line-height: 1.8;
                    color: #555;
                    font-weight: 600;

                    img {
                        height: 2.4rem;
                        width: auto;
                        margin-right: 1em;
                        aspect-ratio: 1;
                        transform: translate(0, 25%);
                    }

                    &:nth-child(4) {
                        margin-bottom: 1.44em;
                    }

                    // text-align: center;
                }

                a {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;
                    padding: 0.64em 1.84em;
                    // background: #5CDA42;
                    background: #181818;
                    color: #efefef;
                    font-size: 1.24rem;
                    font-weight: 600;
                    border-radius: 5em;
                    // transition: 0.24s;

                    &:hover {
                        background: #3E83FB;
                        color: white;
                    }
                }
            }
        }

        .part2 {
            height: inherit;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-end;
            padding-right: 5%;
            height: inherit;
            max-height: inherit;
            position: relative;
            overflow: visible;

            // background: #2277ff;
            div {
                // background: #ececff;
                // background: #F6D8AE;//very nice skin color
                background: #F0F0E4;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                justify-content: flex-end;
                align-items: center;
                width: 80%;
                border-radius: 24em;
                aspect-ratio: 1;



                img {
                    width: 88%;
                    display: block;
                    transform: translate(0, 2%);
                    margin: 0;
                }
            }

            .hello-btn {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                padding: 1em 2em;
                font-size: 1.24em;
                font-weight: 600;
                background: var(--bg-nav);
                color: var(--nav-text);
                border: none;
                border-radius: 0.5em;
                width: 50%;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .techskills {
                position: absolute;
                display: block;
                height: 60px;
                width: 60px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
            }
        }
    }
}


@keyframes drone_move {
    from {
        transform: translate(-10vw, 0)rotateZ(24deg);
    }

    to {
        transform: translate(110vw, 0)rotateZ(24deg);
    }
}


@media screen and (max-width: 920px) {
    .hero-area {
        .hero-sub-area {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
            grid-template-areas: 'text' 'graphics';
            max-width: 1980px;
            position: relative;
            overflow: visible;

            .part1 {
                padding-top: 10em;
                padding-bottom: 0;

                .text-slot {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    margin: 4em 0;

                    h1 {
                        line-height: 1;
                        margin: 0 0 0.14em 0;
                        font-size: 3.4rem;
                        margin-bottom: 0.54em;
                    }

                    h2 {
                        font-size: 2rem;
                        margin-bottom: 0.4em;
                    }

                    h3 {
                        font-size: 1.73rem;
                        line-height: 1.5;
                        font-weight: 900;
                        margin: 0 0 2.04em 0;
                        text-align: center;
                    }

                    a {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;
                        align-items: center;
                        padding: 0.94em 2.24em;
                        font-size: 1.54rem;
                        font-weight: bold;
                        border-radius: 5em;
                        transition: 0.24s;
                    }
                }
            }

            .part2 {
                align-items: center;

                div {
                    width: 88%;

                    img {
                        width: 90%;
                        display: block;
                        transform: translate(0, 2%);
                        margin: 0;
                    }

                }

                .hello-btn {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    padding: 1em 2em;
                    font-size: 1.24em;
                    font-weight: 600;
                    background: var(--bg-nav);
                    color: var(--nav-text);
                    border: none;
                    border-radius: 0.5em;
                    width: 50%;
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .techskills {
                    position: absolute;
                    display: block;
                    height: 60px;
                    width: 60px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                }
            }
        }
    }
}

.img-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 1000000;

    img {
        height: calc(inherit*0.8);
    }
}