@import '../text-styles';
@import '../color-styles';



.socials-holder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    // padding: 1em;
    // background: #000;
    position: fixed;
    top: 0;
    right: 24px;
    z-index: 11;

    ul {
        list-style-type: none;
        padding: 0.64em;
        border-radius: 3em;
        background: transparent;
        // background: #0A2239;
        li {
            // background: coral;
            padding: 1em 0;
            position: relative;
            i{
                color: #2277ff;
            }
            &::before {
                content: '';
                color: #444;
                display: block;
                background: #efefef;
                position: absolute;
                right: 200%;
                top: 50%;
                transform: translate(0,-50%);
                height: auto;
                width: auto;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                opacity: 0;
                border-radius: 4px;
                font-size: 1.14rem;
                padding: 0.4em 1em;
                box-shadow: 0 0 0 1px rgba(0,0,0,0.24);
            }
            &:nth-child(1)::before{
                content: 'twitter';
            }
            &:nth-child(2)::before{
                content: 'instagram';
            }
            &:nth-child(3)::before{
                content: 'linkedin';
            }
            

            &:hover {
                -webkit-animation: vibrate-2 0.7s linear infinite both;
                animation: vibrate-2 0.7s linear infinite both;

            }
            &:hover::before{
                opacity:1;
            }

        }
    }

    &::before {
        content: '';
        // background: #1ECBE1;
        top: 0%;
        left: 50%;
        position: relative;
        transform: translate(-50%, 0);
        width: 3px;
        height: 60vh;
        display: block;
        // margin: 1em auto;
    }
    &::after {
        content: '';
        background: #2277ff;
        top: 100%;
        left: 50%;
        position: relative;
        transform: translate(-50%, 0);
        width: 3px;
        height: 24vh;
        display: block;
        // margin: 1em auto;
    }
}



@-webkit-keyframes vibrate-2 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }

    20% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }

    40% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }

    60% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }

    80% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@keyframes vibrate-2 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }

    20% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }

    40% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }

    60% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }

    80% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}


@media screen and (max-width: 920px){
   .socials-holder{
    display: none;
   }
}