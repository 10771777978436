@import '../color-styles';
@import '../text-styles';

.projects {
    // background: $bg-light;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    margin: 4em auto 9em auto;
    padding: 5em 3em 5em 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    max-width: 1400px;
    display: grid;
    grid-template-columns: 2fr 3fr;
    background: #F0F0E4;
    border-radius: 2.4em;
    // box-shadow: 0 0 10px 4px rgba(0,0,100,0.14);

    .image-section {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100%;

        img {
            border-radius: 50%;
            height: 324px;
            aspect-ratio: 1;
        }
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        // background: coral;
        // width: 100%;

        h3 {
            max-width: 800px;
            // background: cornflowerblue;
            font-size: 1.54rem;
            font-weight: 500;
            line-height: 1.5;
            // text-align: center;
            margin-bottom: 2em;
            color: var(--heading-text);
        }

        .stack ul {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: 84%;
            // background: turquoise;
            font-family: 'poppins';
            list-style-type: none;
            font-weight: 400;

            li {
                font-size: 0.94rem;
                margin-right: 1em;
                margin-bottom: 1em;
                background: #333;
                color: #efefef;
                padding: 0.34em 1.4em;
                // box-shadow: 0 0 0 1px #111;
                border-radius: 3em;
            }
        }
    }
}

@media screen and (max-width:920px) {
    .projects{
        grid-template-columns: 1fr;
        div{
            padding: 3em;
           
        }
        .image-section{
            height: auto;
        }
    }
}