@import url("https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@400;500;600;700&family=Battambang:wght@300;400;700&family=Bricolage+Grotesque:opsz,wght@10..48,200;10..48,300;10..48,400;10..48,500;10..48,600;10..48,700;10..48,800&family=Bungee+Inline&family=Chakra+Petch:wght@300;400;500;600;700&family=Comfortaa:wght@300;400;500;600;700&family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Cutive+Mono&family=IBM+Plex+Mono:ital,wght@0,400;0,600;0,700;1,500&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Inter:wght@200;300;400;500;600;700;800;900&family=Limelight&family=Noto+Sans+Display:wght@300;400;500;600;700;800&family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Oswald:wght@300;400;500;600;700&family=Oxygen:wght@300;400;700&family=PT+Sans:ital,wght@0,400;0,700;1,400&family=Poppins:wght@200;300;400;500;600;700;900&family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,700&family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
.display1,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: "poppins", sans-serif;
  color: #444;
}

.display1 {
  font-size: 96px;
}

h1 {
  font-size: 3.5rem;
  line-height: 1.4;
}

h2 {
  font-size: 3rem;
  line-height: 1.5;
}

h3 {
  font-size: 2rem;
  line-height: 1.2;
}

h4 {
  font-size: 1.5rem;
  line-height: 1.2;
}

h5 {
  font-size: 1.25rem;
  line-height: 1.2;
}

h6 {
  font-size: 0.81rem;
  line-height: 1.2;
}

p {
  font-size: 1.25rem;
  line-height: 1.6;
}

a {
  font-size: 1rem;
  text-decoration: none;
}

@media screen and (max-width: 900px) {
  html {
    font-size: 11px;
  }
}
.socials-holder {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: fixed;
  top: 0;
  right: 24px;
  z-index: 11;
}
.socials-holder ul {
  list-style-type: none;
  padding: 0.64em;
  border-radius: 3em;
  background: transparent;
}
.socials-holder ul li {
  padding: 1em 0;
  position: relative;
}
.socials-holder ul li i {
  color: #2277ff;
}
.socials-holder ul li::before {
  content: "";
  color: #444;
  display: block;
  background: #efefef;
  position: absolute;
  right: 200%;
  top: 50%;
  transform: translate(0, -50%);
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  opacity: 0;
  border-radius: 4px;
  font-size: 1.14rem;
  padding: 0.4em 1em;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.24);
}
.socials-holder ul li:nth-child(1)::before {
  content: "twitter";
}
.socials-holder ul li:nth-child(2)::before {
  content: "instagram";
}
.socials-holder ul li:nth-child(3)::before {
  content: "linkedin";
}
.socials-holder ul li:hover {
  animation: vibrate-2 0.7s linear infinite both;
}
.socials-holder ul li:hover::before {
  opacity: 1;
}
.socials-holder::before {
  content: "";
  top: 0%;
  left: 50%;
  position: relative;
  transform: translate(-50%, 0);
  width: 3px;
  height: 60vh;
  display: block;
}
.socials-holder::after {
  content: "";
  background: #2277ff;
  top: 100%;
  left: 50%;
  position: relative;
  transform: translate(-50%, 0);
  width: 3px;
  height: 24vh;
  display: block;
}
@keyframes vibrate-2 {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(2px, -2px);
  }
  40% {
    transform: translate(2px, 2px);
  }
  60% {
    transform: translate(-2px, 2px);
  }
  80% {
    transform: translate(-2px, -2px);
  }
  100% {
    transform: translate(0);
  }
}
@media screen and (max-width: 920px) {
  .socials-holder {
    display: none;
  }
}/*# sourceMappingURL=Socialsholder.css.map */