@import url("https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@400;500;600;700&family=Battambang:wght@300;400;700&family=Bricolage+Grotesque:opsz,wght@10..48,200;10..48,300;10..48,400;10..48,500;10..48,600;10..48,700;10..48,800&family=Bungee+Inline&family=Chakra+Petch:wght@300;400;500;600;700&family=Comfortaa:wght@300;400;500;600;700&family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Cutive+Mono&family=IBM+Plex+Mono:ital,wght@0,400;0,600;0,700;1,500&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Inter:wght@200;300;400;500;600;700;800;900&family=Limelight&family=Noto+Sans+Display:wght@300;400;500;600;700;800&family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Oswald:wght@300;400;500;600;700&family=Oxygen:wght@300;400;700&family=PT+Sans:ital,wght@0,400;0,700;1,400&family=Poppins:wght@200;300;400;500;600;700;900&family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,700&family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
.display1,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: "poppins", sans-serif;
  color: #444;
}

.display1 {
  font-size: 96px;
}

h1 {
  font-size: 3.5rem;
  line-height: 1.4;
}

h2 {
  font-size: 3rem;
  line-height: 1.5;
}

h3 {
  font-size: 2rem;
  line-height: 1.2;
}

h4 {
  font-size: 1.5rem;
  line-height: 1.2;
}

h5 {
  font-size: 1.25rem;
  line-height: 1.2;
}

h6 {
  font-size: 0.81rem;
  line-height: 1.2;
}

p {
  font-size: 1.25rem;
  line-height: 1.6;
}

a {
  font-size: 1rem;
  text-decoration: none;
}

@media screen and (max-width: 900px) {
  html {
    font-size: 11px;
  }
}
.hero-area {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  overflow: visible;
  position: relative;
}
.hero-area .images {
  position: absolute;
  height: 80px;
  left: -10vw;
  animation: drone_move 9s linear infinite;
}
.hero-area .images:nth-child(1) {
  top: 20px;
  animation-delay: 3s;
}
.hero-area .images:nth-child(2) {
  top: 54px;
  animation-delay: 1s;
  height: 60px;
}
.hero-area .images:nth-child(3) {
  top: 20px;
  z-index: 100;
  animation-delay: 5s;
}
.hero-area .hero-sub-area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-width: 1240px;
  height: inherit;
  margin: auto;
  background: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: visible;
}
.hero-area .hero-sub-area .gradient-roller {
  position: absolute;
  opacity: 0.3;
  top: 100%;
  left: 0;
  height: 70%;
  width: 70%;
  transform: translate(-50%, -50%);
  z-index: -1;
  filter: blur(100px);
  display: block;
}
.hero-area .hero-sub-area .gradient-roller::before {
  content: "";
  display: block;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  height: inherit;
  width: inherit;
}
.hero-area .hero-sub-area .gradient-roller:nth-child(2) {
  top: 100%;
  left: 20%;
  background-image: radial-gradient(circle, rgb(63, 94, 251) 0%, rgb(252, 70, 107) 100%);
}
.hero-area .hero-sub-area .part1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: inherit;
  position: relative;
  overflow: visible;
  padding: 9em 0;
}
.hero-area .hero-sub-area .part1 .text-slot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 2.4em 0;
}
.hero-area .hero-sub-area .part1 .text-slot h1 {
  font-weight: 500;
  line-height: 1;
  margin: 0 0 0.24em 0;
  color: #555;
  font-size: 4.24rem;
}
.hero-area .hero-sub-area .part1 .text-slot h1 strong {
  font-weight: 500;
  color: #3d76ca;
}
.hero-area .hero-sub-area .part1 .text-slot h2 {
  font-weight: 500;
  font-size: 1.74rem;
  margin-bottom: 1.24em;
  color: #029602;
  position: relative;
  height: 54px;
}
.hero-area .hero-sub-area .part1 .text-slot h2 img {
  height: 44px;
  transform: translate(0, 35%);
  margin: 0 1em 0 0;
}
.hero-area .hero-sub-area .part1 .text-slot h3 {
  font-size: 1.53rem;
  line-height: 1.8;
  color: #555;
  font-weight: 600;
}
.hero-area .hero-sub-area .part1 .text-slot h3 img {
  height: 2.4rem;
  width: auto;
  margin-right: 1em;
  aspect-ratio: 1;
  transform: translate(0, 25%);
}
.hero-area .hero-sub-area .part1 .text-slot h3:nth-child(4) {
  margin-bottom: 1.44em;
}
.hero-area .hero-sub-area .part1 .text-slot a {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0.64em 1.84em;
  background: #181818;
  color: #efefef;
  font-size: 1.24rem;
  font-weight: 600;
  border-radius: 5em;
}
.hero-area .hero-sub-area .part1 .text-slot a:hover {
  background: #3E83FB;
  color: white;
}
.hero-area .hero-sub-area .part2 {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  padding-right: 5%;
  height: inherit;
  max-height: inherit;
  position: relative;
  overflow: visible;
}
.hero-area .hero-sub-area .part2 div {
  background: #F0F0E4;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
  border-radius: 24em;
  aspect-ratio: 1;
}
.hero-area .hero-sub-area .part2 div img {
  width: 88%;
  display: block;
  transform: translate(0, 2%);
  margin: 0;
}
.hero-area .hero-sub-area .part2 .hello-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1em 2em;
  font-size: 1.24em;
  font-weight: 600;
  background: var(--bg-nav);
  color: var(--nav-text);
  border: none;
  border-radius: 0.5em;
  width: 50%;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hero-area .hero-sub-area .part2 .techskills {
  position: absolute;
  display: block;
  height: 60px;
  width: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

@keyframes drone_move {
  from {
    transform: translate(-10vw, 0) rotateZ(24deg);
  }
  to {
    transform: translate(110vw, 0) rotateZ(24deg);
  }
}
@media screen and (max-width: 920px) {
  .hero-area .hero-sub-area {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "text" "graphics";
    max-width: 1980px;
    position: relative;
    overflow: visible;
  }
  .hero-area .hero-sub-area .part1 {
    padding-top: 10em;
    padding-bottom: 0;
  }
  .hero-area .hero-sub-area .part1 .text-slot {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 4em 0;
  }
  .hero-area .hero-sub-area .part1 .text-slot h1 {
    line-height: 1;
    margin: 0 0 0.14em 0;
    font-size: 3.4rem;
    margin-bottom: 0.54em;
  }
  .hero-area .hero-sub-area .part1 .text-slot h2 {
    font-size: 2rem;
    margin-bottom: 0.4em;
  }
  .hero-area .hero-sub-area .part1 .text-slot h3 {
    font-size: 1.73rem;
    line-height: 1.5;
    font-weight: 900;
    margin: 0 0 2.04em 0;
    text-align: center;
  }
  .hero-area .hero-sub-area .part1 .text-slot a {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0.94em 2.24em;
    font-size: 1.54rem;
    font-weight: bold;
    border-radius: 5em;
    transition: 0.24s;
  }
  .hero-area .hero-sub-area .part2 {
    align-items: center;
  }
  .hero-area .hero-sub-area .part2 div {
    width: 88%;
  }
  .hero-area .hero-sub-area .part2 div img {
    width: 90%;
    display: block;
    transform: translate(0, 2%);
    margin: 0;
  }
  .hero-area .hero-sub-area .part2 .hello-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 1em 2em;
    font-size: 1.24em;
    font-weight: 600;
    background: var(--bg-nav);
    color: var(--nav-text);
    border: none;
    border-radius: 0.5em;
    width: 50%;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .hero-area .hero-sub-area .part2 .techskills {
    position: absolute;
    display: block;
    height: 60px;
    width: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}
.img-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 1000000;
}
.img-holder img {
  height: calc(inherit * 0.8);
}/*# sourceMappingURL=Hero.css.map */