@import url("https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@400;500;600;700&family=Battambang:wght@300;400;700&family=Bricolage+Grotesque:opsz,wght@10..48,200;10..48,300;10..48,400;10..48,500;10..48,600;10..48,700;10..48,800&family=Bungee+Inline&family=Chakra+Petch:wght@300;400;500;600;700&family=Comfortaa:wght@300;400;500;600;700&family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Cutive+Mono&family=IBM+Plex+Mono:ital,wght@0,400;0,600;0,700;1,500&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Inter:wght@200;300;400;500;600;700;800;900&family=Limelight&family=Noto+Sans+Display:wght@300;400;500;600;700;800&family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Oswald:wght@300;400;500;600;700&family=Oxygen:wght@300;400;700&family=PT+Sans:ital,wght@0,400;0,700;1,400&family=Poppins:wght@200;300;400;500;600;700;900&family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,700&family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
.display1,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: "poppins", sans-serif;
  color: #444;
}

.display1 {
  font-size: 96px;
}

h1 {
  font-size: 3.5rem;
  line-height: 1.4;
}

h2 {
  font-size: 3rem;
  line-height: 1.5;
}

h3 {
  font-size: 2rem;
  line-height: 1.2;
}

h4 {
  font-size: 1.5rem;
  line-height: 1.2;
}

h5 {
  font-size: 1.25rem;
  line-height: 1.2;
}

h6 {
  font-size: 0.81rem;
  line-height: 1.2;
}

p {
  font-size: 1.25rem;
  line-height: 1.6;
}

a {
  font-size: 1rem;
  text-decoration: none;
}

@media screen and (max-width: 900px) {
  html {
    font-size: 11px;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highloght-color: transparent;
}

nav {
  top: 0;
  left: 0;
  right: 0;
  height: 78px;
  padding-top: 1.93em;
  z-index: 1;
  border: 0;
  width: 100vw;
  overflow: hidden;
}
nav div {
  max-width: 1240px;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: inherit;
  justify-content: space-between;
  margin: auto;
  max-width: 1240px;
}
nav div .nav-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 0;
  background: transparent;
  color: #444;
  text-decoration: none;
  font-size: 1.73rem;
  font-weight: 600;
}
nav div .nav-bar {
  background: transparent;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  padding-right: 5em;
}
nav div .nav-bar .nav-links {
  text-decoration: none;
  background: transparent;
  text-decoration: none;
  width: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.14em;
  font-weight: 500;
  position: relative;
  color: #111;
  margin-left: 2em;
}
nav div .nav-bar .nav-links:hover {
  color: #2b6eda;
}
nav div .nav-bar .nav-links::before {
  content: "";
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  background: #fefefe;
  height: 1px;
  width: 0;
  transition: 0.14s;
  z-index: 1;
}
nav div .nav-bar .nav-links:hover::before {
  width: 100px;
}
nav div .menu-btn {
  height: inherit;
  width: auto;
  aspect-ratio: 1/1;
  overflow: hidden;
  display: none;
  justify-content: center;
  align-items: center;
  transition-duration: 0.34s;
  position: relative;
  margin: 0;
}
nav div .menu-btn:focus, nav div .menu-btn:active {
  -webkit-tap-highloght-color: transparent;
}
nav div .menu-btn img {
  height: 27.5px;
}
nav div .menu-btn .upper-menu-bar,
nav div .menu-btn .lower-menu-bar,
nav div .menu-btn .lower-menu-bar-close,
nav div .menu-btn .upper-menu-bar-close {
  background: #000;
  height: 2px;
  width: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 400ms;
}
nav div .menu-btn .upper-menu-bar {
  transform: translate(-50%, -5px);
}
nav div .menu-btn .lower-menu-bar {
  transform: translate(-50%, 5px);
}
nav div .menu-btn .upper-menu-bar-close {
  transform: translate(-50%, 0) rotateZ(-45deg);
}
nav div .menu-btn .lower-menu-bar-close {
  transform: translate(-50%, 0) rotate(45deg);
}
nav div .mobile-menu {
  display: flex;
  height: 0;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--bg-nav);
  z-index: 300;
  transition: height 0.34s ease-in-out;
  overflow: hidden;
  flex-direction: row;
  justify-content: flex-start;
}
nav div .mobile-menu .nav-bar {
  background: transparent;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: inherit;
}
nav div .mobile-menu .nav-bar .nav-links {
  text-decoration: none;
  background: transparent;
  color: var(--nav-text);
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  font-size: 2rem;
  width: inherit;
  padding: 1em 0;
}
@media screen and (max-width: 920px) {
  nav nav {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    background: blue;
    z-index: 11;
  }
  nav div .nav-logo {
    margin-left: 1.04em;
  }
  nav div .menu-btn {
    display: flex;
  }
  nav div .nav-bar {
    display: none;
  }
  nav div .nav-bar .nav-links {
    display: none;
  }
}
nav .mobile-menu {
  display: none;
  height: 0;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--bg-nav);
  z-index: 3;
  transition: height 0.34s ease-in-out;
  overflow: hidden;
  flex-direction: row;
  justify-content: flex-start;
}
nav .mobile-menu .nav-bar {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: inherit;
}
nav .mobile-menu .nav-bar .nav-links {
  text-decoration: none;
  color: var(--nav-text);
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  font-size: 2rem;
  width: inherit;
  padding: 1em 0;
}/*# sourceMappingURL=Navbar.css.map */