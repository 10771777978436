@import 'text-styles';
@import 'color-styles';

.light {
  --main-blue:#{$primary-color-1};
  --bg-color: #{$neutral-color-1};
  --bg-nav: #{$neutral-color-1};
  --nav-text: #{$neutral-color-8};
  --heading-text:#{$neutral-color-8};
  --subheading-text:#{$neutral-color-7};

  // --brand-twitter: #{$brand-twitter};
  // --brand-linkedin: #{$brand-linkedin};
  // --brand-instagram: #{$brand-instagram};
}

.dark {
  --main-blue:#{$primary-color-1-dark};
  --bg-color: #{$neutral-color-1-dark};
  --bg-nav: #{$neutral-color-1-dark};
  --nav-text: #{$neutral-color-8-dark};
  --heading-text:#{$neutral-color-white};
  --subheading-text:#{$neutral-color-7-dark};

  // --brand-twitter: var(--main-blue);
  // --brand-linkedin: var(--main-blue);
  // --brand-instagram: var(--main-blue);
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $secondary-color-1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $secondary-color-4;
  border-radius: 3em;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: linear-gradient(rgba(255,255,255,0.9),rgba(255,255,255,0.9)), url('../public/images/Vorderrhein.svg');
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  background: var(--bg-color);
  // transition: 0.3s ease-in-out;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}