  @import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@400;500;600;700&family=Battambang:wght@300;400;700&family=Bricolage+Grotesque:opsz,wght@10..48,200;10..48,300;10..48,400;10..48,500;10..48,600;10..48,700;10..48,800&family=Bungee+Inline&family=Chakra+Petch:wght@300;400;500;600;700&family=Comfortaa:wght@300;400;500;600;700&family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Cutive+Mono&family=IBM+Plex+Mono:ital,wght@0,400;0,600;0,700;1,500&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Inter:wght@200;300;400;500;600;700;800;900&family=Limelight&family=Noto+Sans+Display:wght@300;400;500;600;700;800&family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Oswald:wght@300;400;500;600;700&family=Oxygen:wght@300;400;700&family=PT+Sans:ital,wght@0,400;0,700;1,400&family=Poppins:wght@200;300;400;500;600;700;900&family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,700&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
.display1,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a {
      font-family: 'poppins', sans-serif;
      color: #444;
  }

  .display1 {
      font-size: 96px;
  }

  h1 {
      font-size: 3.5rem;
      line-height: 1.4;
  }

  h2 {
      font-size: 3rem;
      line-height: 1.5;
  }

  h3 {
      font-size: 2rem;
      line-height: 1.2;
  }

  h4 {
      font-size: 1.5rem;
      line-height: 1.2;
  }

  h5 {
      font-size: 1.25rem;
      line-height: 1.2;
  }

  h6 {
      font-size: 0.81rem;
      line-height: 1.2;
  }

  p {
      font-size: 1.25rem;
      line-height: 1.6;
  }

  a {
      font-size: 1rem;
      text-decoration: none;
  }

  @media screen and (max-width: 900px) {
      html {
          font-size: 11px;
      }
  }